@font-face {
	font-family: Inter;
	src: url("./assets/fonts/Inter/static/Inter-Regular.ttf");
}

@font-face {
	font-family: Gantari;
	src: url("./assets/fonts/Gantari/static/Gantari-Regular.ttf");
}
@font-face {
	font-family: Roboto-Medium;
	src: url("./assets/fonts/Roboto/Roboto-Medium.ttf");
}
@font-face {
	font-family: Roboto;
	src: url("./assets/fonts/Roboto/Roboto-Regular.ttf");
}

body {
	margin: 0;
	font-family: "Inter", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f6f8fa;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}
