#users {
	font-family: Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	width: auto;
	transition: all 5s ease;
	/* table-layout: fixed; */
}
#users td,
#users th {
	border: 1px solid #ddd;
	padding: 8px 10px;
	overflow: hidden;
	cursor: pointer;
	text-align: center;
	transition: all 5s ease;
}

#users tr:nth-child(odd) {
	background-color: #e4ebf6;
}


#users tr:hover {
	background-color: #ddd;
}

#users th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: center;
	background-color: #2b579a;
	color: white;
	width: fit-content;
}

.paginationButton {
	height: 40px;
	width: 120px;
	margin-left: 30px;

	font-size: 20px;
	background-color: #2b579a;
	color: white;
	cursor: pointer;
	border: 2px solid #2b579a;
	border-radius: 5px;
}

.currentPagespan {
	font-size: 20px;
	margin-left: 20px;
}

.userTablePagination {
	font-size: 20px;
	margin: 20px;
}

.userTablePagination select {
	height: 30px;
	width: 100px;
	font-size: 20px;
}

.searchContainer input {
	height: 40px;
	width: 170px;
	border: 1px solid black;
	padding-left: 10px;
	font-size: 20px;
	border-radius: 10px;
}

.searchbar input {
	height: 40px;
	width: 145px;
	border: 1px solid black;
	padding-left: 10px;
	font-size: 20px;
	border-radius: 10px;
}




.leaderboardSubmitV {
	height: 40px;
	width: 120px;
	margin-left: 30px;
	margin-right: 30px;

	font-size: 20px;
	background-color: #2b579a;
	color: white;
	cursor: pointer;
	border: 2px solid #2b579a;
	border-radius: 5px;
}
.allofferSubmit {
	height: 40px;
	width: 100px;
	/* font-size: 20px; */
	font: 600 14px / 17px "Inter";
	background-color: #2b579a;
	color: white;
	cursor: pointer;
	border: 2px solid #2b579a;
	border-radius: 5px;
}

.allofferSubmit2{
	height: 40px;
	width: 100%;
	/* font-size: 20px; */
	font: 600 14px / 17px "Inter";
	background-color: #2b579a;
	color: white;
	cursor: pointer;
	border: 2px solid #2b579a;
	border-radius: 5px;
}

.leaderboardButton{

	height: 40px;
	width: 120px;
	margin-left: 30px;

	font-size: 20px;
	background-color: #2b579a;
	color: white;
	cursor: pointer;
	border: 2px solid #2b579a;
	border-radius: 5px;
}

.filterIcon{
	padding: 6px;
	box-sizing: border-box;
	box-shadow: 0px 1px 3px 0px #0000007d;
	border-radius: 6px;
	display: flex;
	align-items: center;
	cursor: pointer;
	margin:  0 10px;
	position: relative;
	background-color: white;
}

.filterArea{
	position: absolute;
	top: 120%;
	right: -500px;
	border-radius: 3px;
	width: 280px;
	height: auto;
	background-color: white;
	box-shadow: 0px 1px 3px 0px #0000007d;
	padding: 8px;
	box-sizing: border-box;
transition: all 0.3s ease-in-out;
	align-items: center;
	flex-wrap: wrap;
	display: flex;
	opacity: 0;
	visibility:hidden ;
}
.filterArea.active{
	right: 0;
	opacity: 1;
	visibility: visible;
}
.filterArea .m10{
margin-bottom: 10px;
}
.submitArea{
	width: 100%;
}
.dFlex.w70 span{
margin: 0 10px 0 0;
}
.mr3{
	margin:0 3px 0 0;
}
.fHead{
	font: 600 14px / 17px "Gantari";
}
.submitArea p{
	background-color: #2b579a;
	margin: 5px 0;
	padding: 6px 10px;
	color: white;
	cursor: pointer;
	width: 100%;
	text-align: center;
	border-radius: 3px;
	font: 600 14px / 17px "Gantari";
}







/* ============================= */

@media only screen and (max-width: 600px) {
	.mainheading {
		font-size: 25px;
		margin-bottom: 10px;
	}

	.editCover {
		margin: 0px;
	}

	.editCover p {
		width: fit-content;
		margin-bottom: 5px;
		color: black;
		font-size: 16px;
		font-weight: 600;
	}

	.userBlockbutton {
		width: 50%;
		padding: 16px 5px;
		font: 600 14px/17px "Inter";
		background-color: #2b579a;
		cursor: pointer;
		box-sizing: border-box;
	}

	.UserCsvdownload {
		display: inline-block;
		width: 40%;
		margin: 0 auto;
	}

	.flexClass {
		display: block;
	}

	.responsiveCsvButton {
		width: -moz-fit-content;
		width: 100%;
		padding: 15px 5px;
		color: #fff;
		margin: 30px auto;
		border-radius: 6px;
		font: 600 12px/17px "Inter";
		background-color: #2b579a;
		cursor: pointer;
		box-sizing: border-box;
	}

	.Usersearchbar {
		width: 40%;
		margin: 0 auto;
		display: inline-block;
	}

	.Usersearchbar input {
		height: 40px;
		width: 100%;
		border: 1px solid black;
		padding-left: 10px;
		font-size: 20px;
		border-radius: 10px;
	}

	.tSubnitButton {
		width: fit-content;
		padding: 10px 15px;
		color: #fff;
		border-radius: 6px;
		font: 500 14px/17px "Inter";
		background-color: #2b579a;
		cursor: pointer;
		box-sizing: border-box;
	}

	.select-field {
		width: 100%;
		height: 50px;
		font-size: 20px;
		border-radius: 10px;
		padding: 10px;
		margin-right: 20px;
		margin-left: 0px;
	}
	.select-field option {
		min-height: 50px;
		height: 80%;
	}

	.userTablePagination {
		font-size: 15px;
		margin: 15px;
		margin-left: 5px;
		font-weight: 600;
	}

	.userTablePagination select {
		height: 35px;
		width: 75px;
		font-size: 20px;
		margin-left: 10px;
		font-weight: 600;
	}

	.currentPagespan {
		font-size: 15px;
		margin-left: 5px;
		font-weight: 600;
	}

	.paginationButton {
		height: 30px;
		width: 100px;
		font-size: 15px;
		font-weight: 600;
		background-color: #2b579a;
		color: white;
		cursor: pointer;
		border: 2px solid #2b579a;
		border-radius: 5px;
		margin-left: 50px;
		margin-top: 10px;
	}
	.searchContainer input {
		height: 40px;
		width: 135px;
		border: 1px solid black;
		padding-left: 10px;
		font-size: 20px;
		border-radius: 10px;
	}
}
