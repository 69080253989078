.sidebar {
	transition-duration: 0.5s;
	background-color: #fff;
	box-shadow: 1px 0px 24px -7px rgba(16, 24, 40, 0.05),
		2px 0px 4px -2px rgba(16, 24, 40, 0.05);
	height: 100vh;
	width: 18%;
	z-index: 1;
	padding: 10px 20px;
	overflow-y: scroll;
	box-sizing: border-box;
	display: flex;
	position: fixed;
	flex-direction: column;
}

.logoBox {
	width: 100px;
	display: block;
	margin: 30px auto;

	display: flex;
	justify-content: space-around;
}

.logoBox img {
	width: 100%;
	height: auto;
}

.mainHead {
	font: 400 14px/16px "Inter";
	color: #aebebc;
	margin: 10px 0 20px;
	padding: 0 5px 0 10px;
	box-sizing: border-box;
}

.sideMenu {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	align-items: center;
	margin: 4px 0;
	min-height: 44px;
	padding: 0 5px 0 10px;
	box-sizing: border-box;
	border-radius: 10px;
	transition-duration: 0.4s;
	color: #6f8381;
	cursor: pointer;
}

.sideMenu svg.config {
	width: 17px;
	height: 17px;
	margin-right: 10px;
	transition-duration: 0.4s;
}

.sideMenu p.menuName {
	font: 600 15px/18px "Inter";
}

.sideMenu:hover,
.sideMenu.active,
.sideMenu.profile.active {
	background-color: #2b579a;
	color: white;
}

.sideMenu:hover svg.config path,
.sideMenu.active svg.config path,
.sideMenu.profile.active svg.arrow path {
	stroke: #fff !important;
}

.menuCover {
	flex: 1;
}

.proflieBox {
	width: 100%;
	cursor: pointer;
}

.sideMenu.profile {
	padding: 10px;
	color:white;
	background-color: #f1f4f3;
}

.sideMenu.profile img {
	width: 40px;
	height: 40px;
	border-radius: 100%;
	margin-right: 10px;
}

.sideMenu svg.arrow {
	width: 7px;
	height: 17px;
	margin-left: auto;
	transition-duration: 0.4s;
}

.adminName {
	width: 30px;
	height: 30px;
	font: 800 25px/29px "Inter";
	border-radius: 50%;
	background-color: rgb(244, 110, 110);
	text-align: center;
	
}



/* ============================================ */
.hamburger {
	display: none;
}







@media only screen and (max-width: 600px) {
	.hamburger {
		display: block;
		width: 5%;
		cursor: pointer;
		padding: 20px;
		background-color: #decfcf;
		color: #000;
		font-size: 30px;
		font-weight: 600;
	}

	.hamburgerParentDiv {
		display: block;
		width: 100%;
		background-color: #decfcf;
		position: sticky;
		top: 0;
		z-index: 99999
	}

	.sidebar {
		display: none;
	}

	.sidebar.active {
		display: block;
		z-index: 2;
		margin: auto;
		transition: all 0.5s ease-in-out;
		width: 50% !important;
		height: 765px;
		overflow-y: auto;
	}

	.revenueAnalytic {
		display: block;
	}

	.revenueAnalytic {
		width: 100%;
	}

	.totalrevBox {
		width: 99%;
	}

	.user-date-filter {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-items: center;
		margin-top: 15px;
		gap: 3%;
	}

	.user-date-filter.gap0 {
		gap: 0;
	}

	.graphBox.userGrapBox {
		width: 40%;
		height: 50px;
		margin: 0 3% 15px 0;
		background-color: white;
		padding: 14px 8px;
		border-radius: 10px;
		border: 2px solid rgba(43, 87, 154, 1);
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
	}

	.graphBox {
		width: 100%;
		height: auto;
		margin: 0 3% 15px 0;
		background-color: white;
		padding: 14px 8px;
		border-radius: 10px;
		border: 2px solid rgba(43, 87, 154, 1);
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
	}

	.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
		width: 95% !important;
	}

	.selectFiltor {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 45%;
		padding: 0px 0px;
	}

	.selectFiltor>p {
		font-size: 20px;
		margin-left: 10px;
	}

	.fWrap {
		display: flex;
		flex-wrap: wrap;
	}

	.graphBoxHead {
		width: 100%;
		color: rgba(4, 191, 191, 1);
		font: 600 15px/18px "Inter";
		margin-bottom: 1%;
	}

	.analyticsArea {
		width: 100%;
		padding: 20px 0;
	}

	.analyticHead {
		width: 100%;
		color: black;
		margin-bottom: 0px;
		font: 600 20px/25px "Inter";
	}

	.UsertSubnitButton {
		width: -moz-fit-content;
		width: 40%;
		padding: 10px 20px;
		color: #fff;
		border-radius: 6px;
		font: 600 14px/17px "Inter";
		background-color: #2b579a;
		cursor: pointer;
		box-sizing: border-box;
		display: inline-block;
		margin-left: 10px;
	}

	.graphFlex {
		display: flex;
		/* flex-wrap: nowrap; */
		width: 100%;
		padding: 5px 0 0;
		gap: 70px;
		align-items: baseline;
	}

	.screenHeading {
		font: 600 23px/24px "Inter";
		padding-bottom: 10px;
	}

	.revenueStats {
		text-align: center;
		width: 100%;
		font: 600 21px/40px "Inter";
		letter-spacing: -0.01em;
		text-align: center;
	}

	.screen {
		width: 100%;
		padding: 20px;
	}

	.dFlex {
		width: 100%;
	}

	.performedName {
		/* width: 100%; */
		display: block;
		padding: 0px;
		font-size: 18px;
	}

	.analyticHead2 {
		width: 100%;
		color: black;
		margin-bottom: 0px;
		font: 600 20px/25px "Inter";
	}
}