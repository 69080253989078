.loginSection {
	width: 100vw;
	height: 100vh;
	background-color: #1a1a1d;
	box-sizing: border-box;
	align-items: center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.loginBox {
	background-color: rgb(242, 242, 242);
	margin: 10px auto !important;
	border-radius: 5px !important;
	max-width: 450px;
	width: 90%;
	padding: 24px;
	box-sizing: border-box;
}

.loginBox img {
	width: 80px;
	display: block;
	margin: auto;
}

.loginHeading {
	display: flex;
	flex-direction: column;
	-webkit-box-align: center;
	align-items: center;
	margin-top: 16px;
}

.loginHeading h6 {
	margin: 0px;
	letter-spacing: 0.0075em;
	font: 500 1.25rem/1.6 "Be Vietnam Pro", sans-serif;
}

.loginHeading h5 {
	margin: 8px 0px 0px;
	letter-spacing: 0em;
	font: 500 1.5rem/1.33 "Be Vietnam Pro", sans-serif;
	color: rgba(30, 37, 36, 1);
}

.inputBox {
	display: flex;
	flex-direction: column;
	margin-top: 16px;
	width: 100%;
}

.inputCover {
	width: 100%;
	margin-bottom: 10px;
	position: relative;
}

.inputCover input {
	font: inherit;
	letter-spacing: inherit;
	color: currentcolor;
	border: 1px solid rgba(225, 230, 234, 1);
	box-sizing: content-box;
	background: none;
	height: 1.4375em;
	margin: 0px;
	-webkit-tap-highlight-color: transparent;
	display: block;
	min-width: 0px;
	width: 100%;
	padding: 26.5px 14px;
	box-sizing: border-box;
	position: relative;
	border-radius: 3px;
}
.form-group-button {
	position: absolute;
	background-color: transparent;
	padding: 0;
	margin: 0;
	box-shadow: none;
	border: none;
	color: black;
	top: 32%;
	right: 5%;
	font-size: 10px;
}

.submit {
	color: #fff;
	background-color: rgba(111, 106, 248, 1);
	box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
		rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
	width: 100%;
	min-width: 64px;
	padding: 10px 16px;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	border-radius: 3px;
	outline: none;
	border: 0;
}
