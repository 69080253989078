* {
	box-sizing: border-box;
}

.App {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	box-sizing: border-box;
}

.screen {
	padding: 50px 20px;
	width: 82%;
	margin-left: auto;
	box-sizing: border-box;
	background-color: #f6f8fa;
	overflow-x: hidden;
	height: 100vh;
}

.screenHeading {
	font: 600 28px/34px "Roboto";
}

.profileScreenHeading {
	font: 600 28px/34px "Gantari";
	color: rgba(4, 191, 191, 1);
	;
}



.catHead {
	width: fit-content;
}

.profileInfoBox {
	box-shadow: 0;
	width: 100%;
	border-radius: 12px;
	box-sizing: border-box;
}

.dFlex {
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
}

.alignCenter {
	align-items: center;
}

.w100 {
	width: 100%;
}

.trans-upper-div {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.trans-upper-div>div {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.flexBoxes {
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	justify-content: space-between;
	width: 100%;
}

.w50 {
	width: 50%;
}

.configDetail {
	width: 100%;
	padding: 20px 0;
}

.configDetail p {
	width: 100%;
	font: 500 18px/25px "Inter";
	color: #667085;
}

.configDetail p span {
	color: black;
}

.editButton {
	width: fit-content;
	padding: 10px 50px;
	color: #fff;
	margin: 30px auto;
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #2b579a;
	cursor: pointer;
	box-sizing: border-box;
}

.bulkEdit {
	/* width: 100%; */
	padding: 10px 50px;
	background-color: #fff;
	margin: auto;
	cursor: pointer;
	box-sizing: border-box;
	/* position: absolute;
	bottom: 0;
	left: 0; */

}

.bulkTable {
	margin-bottom: 40px;
	overflow: scroll;
	max-height: 500px;
}

.bulkEdit p {
	width: fit-content;
	margin: 0 auto;
	padding: 10px 50px;
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #2b579a;
	color: #fff;
}

.user_trans_b {
	width: fit-content;
	padding: 10px 20px;
	color: #fff;
	/* margin: 30px auto; */
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #2b579a;
	cursor: pointer;
	box-sizing: border-box;
	height: fit-content;
}

.p_e_button {
	/* width: fit-content; */
	padding: 10px 50px;
	color: #fff;
	margin: 10px auto;
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #2b579a;
	cursor: pointer;
	box-sizing: border-box;
	width: 30%;
}

.addofferInAllOffer {
	width: fit-content;
	padding: 10px 20px;
	color: #fff;
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #2b579a;
	cursor: pointer;
	box-sizing: border-box;
}

.addofferButton {
	width: fit-content;
	padding: 10px 20px;
	color: #fff;
	/* margin: 30px auto; */
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #2b579a;
	cursor: pointer;
	box-sizing: border-box;
	text-align: center;
}

.edit-single-offer {
	display: flex;
	justify-content: baseline;
	align-items: center;
	/* background-color: white; */
}

.csvButton {
	width: fit-content;
	padding: 10px 20px;
	color: #fff;
	margin: 30px auto;
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #2b579a;
	cursor: pointer;
	box-sizing: border-box;
	width: 145px;
}



.userCsvButton {
	padding: 10px 20px;
	color: #fff;
	/* margin-right: 15px; */
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #2b579a;
	cursor: pointer;
	box-sizing: border-box;
	width: 145px;
}



.Usersearchbar {
	width: 150px;
	display: inline-block;
}

.Usersearchbar.autoWidth {
	width: auto;
}

.Usersearchbar input {
	height: 35px;
	width: 100%;
	border: 1px solid rgb(65, 65, 65);
	padding-left: 10px;
	box-sizing: border-box;
	font-size: 16px;
	border-radius: 6px;
}

.dFlex-2 {
	display: flex;
	justify-content: right;
	align-items: center;
	gap: 100px;
}

.dFlex-2>div:nth-child(2) {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 3px;
}

.editButtonAdmin {
	width: 100%;
	padding: 10px 50px;
	color: #fff;
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #6f6af8;
	cursor: pointer;
	box-sizing: border-box;
	text-align: center;
}

.editButton.w45 {
	text-align: center;
	padding: 10px 0;
	width: 45%;
}

.dFlex .editButton.addCategory {
	display: flex;
	align-items: center;
	margin: 0 0 0 auto;
}

.editButton.addCategory svg {
	margin-right: 10px;
}

.profileInfoBox.table {
	padding: 0;
	margin-top: 20px;
}

.profileInfoBox.table h5 {
	font: 600 23px/23px "Inter";
	margin: 10px 0;
}

.MuiDataGrid-columnHeaders {
	background-color: #2b579a;
}

.tableHeader {
	font: 600 15px/18px "Gantari";
	background-color: #2b579a;
	color: white;
}

.tableNewRow:nth-child(even) {
	background-color: #e4ebf6;
}

.whiteRound:hover {
	background-color: white;
	border-radius: 100%;
	cursor: pointer;
}

.bulkDialog {
	/* width: 100%; */
	min-width: 500px;
}

.bulkBox {
	min-width: 600px;
	/* width: 100%; */
	padding: 20px;
	height: 100%;
	position: relative;
}

.bulkBox h3 {
	font: 600 23px/23px "Inter";

}

.categoryEditBox {
	padding: 10px;
}

.userCsvDivBulk {
	display: flex;
	align-items: flex-start;
}

.userCsvDivBulk form {
	width: fit-content;
	display: inline-block;
	margin-top: 10px;
	height: 100%;
}

.bulkDialog .MuiPaper-root {
	max-width: 100%;
}

.userCsvDivBulk form label.userCsvButton {
	height: 100%;
}

.categoryEditBoxAdmin {
	width: 450px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	padding: 10px;
}

.categoryEditBox h3 {
	text-align: center;
	width: 100%;
	font: 600 20px/27px "Inter";
	color: #6f6af8;
}

.categoryEditBox p {
	padding: 15px 0;
}

.dFlex .editButton {
	margin: 10px auto;
}

.inputTag.categoryInput,
.inputTag.amount {
	margin: 10px auto;
	width: 100%;
}

.w300 {
	width: 300px;
}

.w300 .editIcon {
	right: 5%;
}

.pRelative.w300 {
	margin: 10px 0;
}

.voucherImage {
	width: 40px;
	border-radius: 100%;
	height: 40px;
	margin: 4px auto 4px 0;
}

/* .voucherRow {
	max-height: 100px !important;
	height: 100%;
} */

/* add bank details csss------------> */
.addOption {
	width: 100%;
	padding: 30px 0 0;
	align-items: center;
	flex-wrap: nowrap;
}

.addBox {
	width: fit-content;
	padding: 30px 20px;
	text-align: center;
	margin-right: 10px;
	border-radius: 10px;
	background-color: white;
}

.addBox:first-child {
	text-align: left;
}

.addBox svg {
	font-size: 3.5rem;
}

.addBox h5 {
	margin: 0 0 15px 0;
	font: 600 20px/24px "Inter";
	color: #6f6af8;
}

.addBox p {
	color: rgba(102, 112, 133, 0.5);
	margin-bottom: 10px;
	font: 500 15px/22px "Inter";
}

.addBox p span {
	color: #667085;
}

.addBox .addIcon {
	width: 52px;
	height: 52px;
}

.bankListBox {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	padding: 15px 0 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.531);
	justify-content: space-between;
}

.Banks {
	display: flex;
	align-items: center;
}

.ml10 {
	margin-left: 10px;
}

.Banks h4 {
	font: 600 15px/19px "Inter";
	display: flex;
	align-items: center;
}

.Banks h5 {
	font: 400 13px/19px "Inter";
}

.verification {
	font: 600 10px/15px "Inter";
	background-color: rgb(75, 75, 152);
	color: white;
	padding: 1px 9px;
	margin-left: 10px;
	display: block;
	border-radius: 4px;
}

.editBank {
	display: flex;
	align-items: center;
}

.editBank svg {
	width: 40px;
	max-width: 40px;
	max-height: 40px;
}

.TransactionBox {
	padding: 8px 10px;
	background: linear-gradient(180deg, #257cff 0%, #77797d 100%);
	border-radius: 35px 35px;
	color: white;
	cursor: pointer;
}

.TransactionBox.disableBox {
	background: linear-gradient(180deg, #808080 0%, #808080 100%);
}

.dBlock {
	/* display: block; */
	display: flex;
	width: fit-content;
	/* flex-direction: row-;	 */
}

.user_trans_mainC_container {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px
}



.dBlock.partnerOfferDblock {
	display: flex;
	flex-direction: column;
	width: fit-content;
}

.sigleOfferdBlock {
	/* display: block; */
	display: flex;
	width: fit-content;
	flex-direction: column;
	background-color: white;
	padding: 15px;
	border-radius: 5px;
	border: 2px solid #2b579a;
}


.dBlock.sigleOfferdBlock.dBlock-width {
	width: 50%;
	background-color: white;
	padding: 15px;
	border-radius: 5px;
	border: 1px solid #2b579a;

}


.catPara {
	margin: 10px 0;
	margin: 10px 0;
	border-bottom: 1px solid #2b579a;
	padding-bottom: 5px;
}

.rewardTable {
	width: 100%;
	margin: 2% 0%;
	;
}

.rewardTable2 {
	width: 100%;
	border-collapse: collapse;
	table-layout: fixed;
}

.rewardTable2 th,
td {
	width: 20%;
	text-align: center;
	border: 2px solid black;
	padding: 8px;
	white-space: nowrap;
	overflow-x: auto;
}

.rewardTable2 .scrollable {
	overflow-x: auto;
}

.flexRow {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
}

.flexRow.detailsFlexRow {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.flexRow h3 {
	width: 16%;
	font: 600 14px/17px "Inter";
	color: rgba(102, 112, 133, 0.7);
	margin-bottom: 9px;
}

.rewards {
	font: 400 14px/17px "Inter";
	/* color: rgba(102, 112, 133, 0.7); */
	font-weight: 700;
	margin-bottom: 9px;
	display: flex;
	align-items: center;
}

.addReward {
	padding: 4px;
	margin-left: 10px;
	border: 1px solid black;
	border-radius: 100%;
	cursor: pointer;
}

.addReward path {
	stroke: black;
}

.editCover.fullCover {
	width: 100%;
	margin-bottom: 10px;
}

.editCover.fullCover p {
	margin: 5px 0;
	padding: 5px 0;
}

.addRew {
	text-align: center;
}

.addButton {
	width: 100%;
	padding: 10px 0;
	text-align: center;
	border-radius: 4px;
	color: white;
	background-color: #6f6af8;
	cursor: pointer;
}

div:has(> .mpaping) {
	display: block !important;
}

.brandsDisplayBox {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding: 30px 0;
}

.displayBox {
	width: 22%;
	padding: 90px 20px;
	margin: 0 auto 20px auto;
	background: linear-gradient(180deg, #2b579a 0%, rgba(9, 29, 0, 0.78) 117.32%);
	box-shadow: 0px 4px 4px rgba(43, 87, 154, 0.1);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.displayBox p {
	font: 800 20px/30px "Inter";
	color: white;
}

.displayBox img {
	width: 100%;
	object-fit: contain;
	margin-bottom: 20px;
	max-height: 50px;
}

.iconImage {
	background-color: black;
	width: 30px;
	height: 30px;
}

.iconImage img {
	width: 100%;
}

.container.app-body {
	background-color: white;
}

.analyticsArea {
	width: 70%;
	padding: 20px 0;
}

.h3Heading {
	font: 600 16px/20px "Inter";
	margin: 0 0 10px 0;
}

.fWrap {
	flex-wrap: wrap;
	width: 100%;
}

.graphBox {
	width: 20%;
	margin: 0 3% 15px 0;
	background-color: white;
	padding: 14px 8px;
	border-radius: 10px;
	border: 2px solid rgba(43, 87, 154, 1);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
}

.userGrapBox {
	width: 10%;
	padding: 0px 0px;
	margin: 0px;
}

.graphBox.userGrapBox {
	box-shadow: none;
	width: 40%;
	max-width: 120px;
}

.selectFiltor {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 15%;
}

.graphBoxInPartner {
	width: 100%;
	margin: 0 3% 15px 0;
	background-color: white;
	/* padding: 14px 8px; */
	border-radius: 10px;
	/* border: 2px solid rgba(43, 87, 154, 1); */
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
}

.leaderboardgraphBox {
	width: 15%;
	margin: 0 3% 15px 0;
	background-color: white;
	padding: 14px 8px;
	border-radius: 10px;
	border: 2px solid rgba(43, 87, 154, 1);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
}

.graphBoxHead {
	width: 100%;
	color: rgba(4, 191, 191, 1);
	font: 500 15px/20px "Inter";
}

.graphFlex {
	display: flex;
	/* flex-wrap: wrap; */
	width: 100%;
	padding: 5px 0 0;
	gap: 10%;
}

.analyticHead {
	width: fit-content;
	color: black;
	margin-bottom: 10px;
	font: 600 18px/20px "Inter";
}

.analyticHead2 {
	font: 600 18px/20px "Inter";
}

.performedName {
	/* width: 100%; */
	display: block;
	padding: 0px 0 6px;
}

.loader {
	width: 120px;
	display: block;
	margin: 0 auto;
}

.clickable {
	color: blue;
}

.revenueAnalytic {
	width: 30%;
	background-color: white;
	border-radius: 10px;
	padding: 20px;
	box-sizing: border-box;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
}

.partnTable {
	width: 100%;
	padding: 20px 0;
}

.partnTable_Cont {
	display: flex;
	padding: 4px 0;
	width: 100%;
	text-align: center;
	box-sizing: border-box;
	background-color: #e5f3fe;
	margin-top: 5px;
	color: #747474;
	font-family: "Inter";
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
}

.partnTable_Cont:nth-child(odd) {
	background-color: white;
}

.cont_header {
	display: flex;
	border-top: 1px solid #5696f859;
	padding: 8px 0;
	color: #747474;
	font-family: "Inter";
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: center;
	box-sizing: border-box;
	border-bottom: 1px solid #5696f859;
}

.cont_header p:first-child,
.partnTable_Cont p:first-child {
	width: 10%;
}

.cont_header p:nth-child(2),
.partnTable_Cont p:nth-child(2) {
	width: 40%;
}

.cont_header p:nth-child(3),
.partnTable_Cont p:nth-child(3) {
	width: 30%;
}

.cont_header p:nth-child(4),
.partnTable_Cont p:nth-child(4) {
	width: 30%;
}

.revenueStats {
	text-align: center;
	width: 100%;
	font: 600 27px/40px "Inter";
	letter-spacing: -0.01em;
	text-align: center;
}

.incBox {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 20px 0;
}

.width50 {
	width: 45%;
	box-sizing: border-box;
	margin: 0 auto 0;
	border: 1px solid #d9d9d9;
	border-radius: 10px;
	padding: 15px 10px;
}

.width50 h5 {
	color: #000000;
	text-align: center;
	font: 600 18px/20px "Inter";
}

.width50 h6 {
	font: 600 20px/25px "Inter";
	margin: 10px 0 0;
	text-align: center;
	color: #0897b7;
}

.revenueFromPart {
	width: 100%;
	text-align: left;
	font: 600 20px/25px "Inter";
}

.totalrevBox {
	width: 95%;
	margin-top: 20px;
	border-radius: 10px;
	box-shadow: 0px 4px 4px 0px #0000001a;
	padding: 10px;
	box-sizing: border-box;
	background-color: white;
}

.editReward {
	width: 40px;
	height: 40px;
}

.dFlex .editButton.addCategory.dBlock {
	/* background-color: white; */
	/* color: black; */
	display: block;
}

.showAdminOption {
	padding: 10px;
	position: absolute;
	width: 90%;
	background-color: #871b52;
	top: 40px;
	left: 0px;
	display: flex;
	flex-direction: column;
	text-align: center;
	border-radius: 11px;
	color: white;
	line-height: 20px;
	z-index: 20;
	cursor: pointer;
}

.hideAdminOption {
	display: none;
}



.drop {
	padding: 10px;
	position: absolute;
	width: 90%;
	background-color: #871b52;
	top: 40px;
	left: 0px;
	display: flex;
	flex-direction: column;
	text-align: center;
	border-radius: 11px;
	color: white;
	line-height: 20px;
	z-index: 20;
	cursor: pointer;
}

.drop_hide {
	display: none;
}

.drop span:hover {
	background-color: #747474;
}

.scratch_show {
	padding: 10px;
	position: absolute;
	width: 100%;
	background-color: white;
	top: 40px;
	left: 0px;
	display: flex;
	flex-direction: column;
	/* text-align: center; */
	border-radius: 11px;
	color: black;
	line-height: 20px;
	z-index: 20;
	cursor: pointer;
	border: 1px solid black;
}

.scratch_hide {
	display: none;
}



.scratch_show label:hover {
	display: block;
	text-align: start !important;
	/* background-color: rgb(135, 95, 95); */
	background-color: #f1f1f1;
}

.show-drop {
	padding: 10px 25px 10px 25px;
	position: absolute;
	width: 100%;
	background-color: white;
	top: 41px;
	left: 0px;
	display: flex;
	flex-direction: column;
	text-align: center;
	border-radius: 11px;
	color: black;
	/* line-height: 20px; */
	z-index: 20;
	cursor: pointer;
}

.hide-drop {
	display: none;
}

.show-drop span:hover {
	background-color: rgb(220, 193, 193);
	border-radius: 3px;
}

.userRight {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 145px;
	padding: 10px 20px;
	color: #fff;
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #2b579a;
	cursor: pointer;
	box-sizing: border-box;
}

.flexClass {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.usersFlex {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 70%;
	gap: 20px;
}

.all-offer-flex {
	width: 100vw;
	display: flex;
	justify-content: right;
	align-items: flex-end;
	/* align-content: center; */
	gap: 15px;
}


.MuiAutocomplete-hasPopupIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
	width: 200px !important;
	margin-right: 50px !important;
}

.MuiAutocomplete-hasPopupIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
	height: 50px !important;
}




.all-offer-flex>div {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 3px;
}

.popular {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.tParentDiv {
	display: flex;
	justify-content: right;
	align-items: center;
	/* flex-wrap: wrap; */
	gap: 10px;
}

.tParentDiv.divGrossData {
	display: flex;
	justify-content: space-between;

	align-items: center;

	gap: 10px;
}

.tSubnitButton {
	width: fit-content;
	padding: 10px 20px;
	color: #fff;
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #2b579a;
	cursor: pointer;
	box-sizing: border-box;
}

.partnerTSubnitButton {
	width: 100%;
	padding: 10px 20px;
	color: #fff;
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #2b579a;
	cursor: pointer;
	box-sizing: border-box;
}

.UsertSubnitButton {
	width: fit-content;
	padding: 10px 20px;
	color: #fff;
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #2b579a;
	cursor: pointer;
	box-sizing: border-box;
	width: 10%;
}

.blocAndStopEarningUser {
	width: fit-content;
	padding: 10px 20px;
	color: rgb(224, 193, 193);
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: rgb(253, 6, 6);
	cursor: pointer;
	box-sizing: border-box;
	width: 10%;
}




.adminPageButton {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.addAdminButton {
	width: fit-content;
	padding: 10px 50px;
	color: #fff;
	border-radius: 6px;
	font: 600 17px/17px "Inter";
	background-color: #2b579a;
	cursor: pointer;
	box-sizing: border-box;
}

.addPartnerButton {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.divimage {
	/* width: 70%; */
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.catImageCover.imgsize {
	height: 100px;
	display: flex;
	align-items: center;
	padding: 4px;
	background-color: #000;
	box-sizing: border-box;
	margin: 10px 0;
}

.catImageCover.imgsize img.cat_image {
	width: 130px;
	height: auto;
}

.addpartnerImage {
	width: 100%;
	max-width: 180px;
	margin-left: 15%;
}

.partnerImage {
	width: 100%;
	max-width: 180px;
}

.partnerImage img {
	width: 100%;
}

.logOutButton {
	width: fit-content;
	padding: 10px 50px;
	color: #fff;
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #2b579a;
	cursor: pointer;
	box-sizing: border-box;
}

.flex {
	display: flex;
	justify-content: space-between;
}

.tableData {
	width: 100%;
}

.addChip {
	width: fit-content;
	padding: 10px 50px;
	color: #fff;
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #2b579a;
	height: 40px;
	margin: 10px 100px;
	cursor: pointer;
	box-sizing: border-box;
}

/* .css-1h51icj-MuiAutocomplete-root {
	margin-right: 40px !important;
} */
.css-159ulw2-MuiPaper-root {
	width: 88% !important;
}

.offerData {
	padding: 10px 10px;
	border: 1px solid black;
	background-color: rgb(255, 255, 255);
	line-height: 1.5em;
	margin: 5px;
	border-radius: 5px;
	overflow-x: scroll;
}

.flexBox {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.border {
	border-bottom: 1px solid black;
	margin-top: 5px;
}

.nextPageButton {
	width: fit-content;
	padding: 10px 50px;
	color: #fff;
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #6f6af8;
	cursor: pointer;
	box-sizing: border-box;
}

.loaderImage {
	display: flex;
	justify-content: center;
	align-items: center;
}

.min-loaderImage {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 50px;
}


/* .loaderImage img {
	object-fit: cover;
	height: 200px;
	width: 200px;
} */

.refer-stat-loader {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.refer-stat-loader img {
	object-fit: cover;
	height: 40px;
	width: 40px;
}

.partnerloader {
	display: flex;
	justify-content: center;
	align-items: center;
}

.partnerloader img {
	object-fit: cover;
	height: 200px;
	width: 200px;
	margin-top: 200px;
}


.partnerloader_revenue {
	display: flex;
	justify-content: center;
	align-items: center;
}

.partnerloader_revenue img {
	object-fit: cover;
	height: 200px;
	width: 200px;
	margin-top: 60px;
}




.banner-image {
	height: 50px;
	width: 50px;
}

.trasitionButton {
	width: 100%;
	padding: 10px 20px;
	color: #fff;
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #2b579a;
	cursor: pointer;
	box-sizing: border-box;
	margin-right: 30px;
}

.leaderboard-main-div {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 15px;
}

.option-div {
	padding: 10px;
	position: absolute;
	width: 90%;
	background-color: white;
	top: 43px;
	left: 3px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	align-items: center;
	border-radius: 11px;
	color: black;
	line-height: 20px;
	z-index: 20;
	cursor: pointer;
}

.option-div span:hover {
	color: #257cff;
}

.option-div-hide {
	display: none;
}

.leaderboard-click {
	width: 250px;
	position: relative;
	background-color: #2b579a;
	text-align: center;
	border-radius: 5px;
	color: white;
	line-height: 40px;
	cursor: pointer;
}

.submit-button button {
	width: fit-content;
	padding: 10px 50px;
	color: #fff;
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #361b87;
	cursor: pointer;
	box-sizing: border-box;
}

.logoutandeditbutton {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: fit-content;
}

.logoutandeditbutton.profileLogOutSubmit {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: fit-content;
	gap: 20px
}



.inlineUserDetail {
	display: inline;
}

.scratchBox {
	width: 200px;
	padding: 10px;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #2b579a;
	cursor: pointer;
	box-sizing: border-box;
}

.tabel-scrol {
	width: 100%;
	overflow-y: hidden;
	overflow-x: auto;
}

.user-date-filter {
	display: flex;
	justify-content: right;
	align-items: center;
	width: 100%;
	margin-top: 15px;
	gap: 25px;
}

.select-field {
	width: 100%;
	height: 56px;
	font-size: medium;
	border-radius: 10px;
	margin-bottom: 16px;
}

.paypal-select {
	width: 100%;
	height: 48px;
	font-size: medium;
	border-radius: 10px;
	margin-right: 20px;
	padding-left: 10px;
}

.recharts-cartesian-axis-tick {
	font-size: 12px;
	font-family: Roboto, sans-serif;
}

.uploadCsv {
	width: fit-content;
	display: flex;
	align-items: center;
}

.flex-div {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 20px;
	margin-bottom: 15px;
}

.labelandInput {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 15px;
	margin-bottom: 20px;
	/* background-color: wheat; */
	/* background-color: #75a0f7; */
	background-color: #d6dbe5;
	padding: 20px;
	border-radius: 30px;
}


.partnerHeading {
	font: 600 20px/20px "Inter";
	padding-bottom: 10px;
	margin-bottom: 10px;
}


.labelClass label {
	display: inline-block;
	width: 30%;
	font-size: 18px;
	font-weight: 600;
}

.challengeLabel label {
	display: inline-block;
	width: 25%;
	font-size: 18px;
	font-weight: 600;
}





.offerDropown label {
	display: inline-block;
	width: 15%;
	font-size: 18px;
	font-weight: 600;
}

.labelClass span {
	font: 600 16px/17px "Inter";

}

.labelClass input {
	border-radius: 50px;
	box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 33%);
	/* color: #667085; */
	padding-left: 25px;
}


.video-section {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 20px
}

.video-section p {
	display: inline-block;
	width: 14%;
	font-size: 18px;
	font-weight: 600;
}





.labelClass input:focus {
	color: black;
}

.inputfield {
	width: 50%;
	background-color: #fdfdfd;
	border-radius: 10px;
	border: 1px solid white;
	outline: none;
	box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 33%);
	height: 1.1375em;
	margin: 0px;
	padding: 26.5px 14px;
	box-sizing: border-box;
	position: relative;
	border-radius: 3px;
	color: #667085;
	font: 600 16px/17px "Inter";
}

.circle {
	margin: 10px 5px 10px 10px;
	;
}

.partnerImage {
	height: 136px;
	max-width: 200px;
	width: 180px;
}

.partnerImage img {
	aspect-ratio: 1;
	display: flex;
	max-height: 120px;
	max-width: 120px;
	height: auto;
	width: 100%;
	object-fit: contain;
}


.spartnerisPopular {
	width: fit-content;
	padding: 10px 50px;
	color: #fff;
	margin: 30px auto;
	border-radius: 6px;
	font: 600 14px/17px "Inter";
	background-color: #2b579a;
	cursor: pointer;
	box-sizing: border-box;
}

.labelClass-partner {
	margin: 0 0 12px 0;
}

.labelClass-partner label {
	margin-right: 10px;
}

.dFlex.w30 {
	width: 30%;
}

.dFlex.w70 {
	width: 70%;
}

.dFlex.w30.fEnd {
	justify-content: flex-end;
	align-items: center;
	width: 50%;
	gap: 10px;
}

.addpartnerImage {
	height: 100px;
	width: 100px;
	margin-left: 30%;
}

.editSingPartnerFlex {
	/* width: fit-content; */
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 30px;
}

.partnerDateFilter {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 20px
}

.grossDataDateFlex {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.editCover.editBio.m10 {
	margin-top: 20px;
}

.flexRow.newFlex h3,
.flexRow div {
	/* width: 33%; */
	width: 18%;
	padding: 5px;
	box-sizing: border-box;
}

.flexRow img {
	width: 100%;
}

/* .categoryEditBox{
		display: flex;
		flex-direction: column;
		margin: 10px auto;
		padding: 10px 0;
	} */
.payPalselect {
	display: flex;
	margin: 10px auto;
	padding: 10px 0;
}

.payPalselect input {
	margin-left: 10px;
}

.paypalInput {
	width: 100%;
	background-color: #fdfdfd;
	border-radius: 10px;
	border: 1px solid white;
	outline: none;
	box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 33%);
	height: 1.1375em;
	margin: 0px;
	padding: 26.5px 14px;
	box-sizing: border-box;
	position: relative;
	border-radius: 3px;
	color: #667085;
	font: 600 14px/17px "Inter";
}

.paypalSearchBar {
	display: inline-block;
	width: fit-content;
}

.paypalSearchBar input {
	height: 40px;
	width: 200px;
	border: 1px solid black;
	padding-left: 10px;
	font-size: 20px;
	border-radius: 10px;
}

.paypalMain {
	display: flex;
	justify-content: flex-end;
	gap: 20px;
	margin-top: 10px;
}

.spaceFlex {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.leaderBoardType {
	display: flex;
	justify-content: flex-end;
	align-items: baseline;
}

.leaderboardSelect {
	width: 25%;
	height: 40px;
	font-size: medium;
	border-radius: 10px;
}

.activeLeaderboard {
	width: 33%;
	margin-bottom: 20px;
	font: 400 14px/17px "Inter";
	/* color: rgba(102, 112, 133, 0.7); */
}

.activeLeaderboard p {
	font: 400 14px/17px "Inter";
	font-weight: 700;
	margin-bottom: 15px;
}

.activeLeaderboard .rounded {
	margin-left: 10px;
}

.rewardTable {
	width: 100%;
	margin-top: 20px;
}

.rewardleaderboard {
	width: 100%;
	margin-top: 20px;
}


.rewardTabTbody {
	width: 100%;
}

.rewardTabTbody td {
	width: 20%;
	text-align: center;
	border: 2px solid black;
}


.rewardTabTbody td img {
	width: 100%;
	height: 50px;
}

.addPrizes {
	width: fit-content;
	display: block;
	margin: 30px 0 10px;
	font: 400 16px/17px "Inter";
	padding: 10px;
	color: white;
	border-radius: 3px;
	background-color: #2b579a;
	cursor: pointer;
}

.earningleaderboadrdFilter {
	display: flex;
	justify-content: flex-end;
	width: 40%;
	gap: 10px;
}

.earningleaderBoardType {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	width: 100%;
}

.earningDropdown {
	display: flex;
	justify-content: flex-end;
	width: 50%;
}

.sendWinningButton {
	height: 40px;
	width: fit-content;
	margin: 20px 0px;
	font-size: 20px;
	background-color: #2b579a;
	color: white;
	cursor: pointer;
	border: 2px solid #2b579a;
	border-radius: 5px;
}

.sendWinningdiv {
	display: flex;
	justify-content: flex-end;
}

.rounded.multiplefalse {
	margin: 0px 10px;
}

.rounded.isSelfMeta {
	margin: 0px 10px;
}


.headingFlexAlloffer {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}



.homebannertable {
	width: 100%;
}



.login-reward-div {
	width: 100%;
	margin-top: 20px;
}

.loginrewards {
	font: 400 14px / 17px "Inter";
	font-weight: 700;
	margin-bottom: 9px;
	display: flex;
	align-items: center;
}


.editCover.offerDropown {
	margin-right: auto;
}

.editCover.offerDropown.availableOn {
	width: 15%;
}

.offerCountryFilter {
	width: 100%;
	display: flex;
	gap: 50px;
	margin-top: 30px;
}

.offerImageFlex {
	display: flex;
	width: 100%;
	margin-top: 30px;
}

.addofferFlex {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}


.offer-m-top {
	margin-top: 30px;
}


.meta-images {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	/* justify-content: center; */
	/* align-content: center; */
}




.h5Heading {
	font: 500 27px / 23px "Gantari";
	color: black;
	background-color: #f6f8fa;
	text-align: center;
}

.swapPosition {
	width: 20%;
	height: 40px;
	font: 600 16px / 17px "Inter";
	;
}

.swapLabel {
	width: 10% !important;
	margin-left: 5%;
}



.pendingEarningFlex {
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
}


.p_dia_flex {
	width: 100%;
	display: flex;
}


.head_30 {
	width: 30%;
	font: 600 14px/17px 'Inter'
}

.d_main_70 {
	width: 70px;
	display: flex;
	gap: 20px;
}

.d_main_70 span {
	width: 100%;
	display: flex;
}


.p_e_submit {
	width: 100%;
	background-color: #257cff;
	text-align: center;
	color: white;
	font: 600 14px/17px "Inter"
}


.note_h_p p {
	padding: 5px 0px !important;
}

.cross_d {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding-right: 10px;
	padding-top: 5px;
}

.cross_d span {
	width: fit-content;
	cursor: pointer;
	font-weight: 600;
}


.dblock-3st-div {
	width: 100%;
	display: flex;
	/* justify-content: space-between; */
	flex-direction: column;
	gap: 20px;

	background-color: white;
	padding: 10px;
	font: 700 14px / 16px "Inter";
	border-radius: 10px;
	border: 2px solid #006064;
}

.user-popup-sec {
	/* width: 50%; */
	width: 100%;
	display: flex;
	/* flex-direction: column; */
	gap: 10px;
	flex-wrap: wrap;
}

.user-transaction-sec {
	/* width: 50%; */
	width: 100%;
	display: flex;
	/* flex-direction: column; */
	gap: 10px;
	flex-wrap: wrap;
}


.trans_filter_box {
	position: absolute;
	top: 120%;
	right: -500px;
	border-radius: 3px;
	/* width: 280px; */
	width: 350px;
	height: auto;
	background-color: white;
	box-shadow: 0px 1px 3px 0px #0000007d;
	padding: 8px;
	box-sizing: border-box;
	transition: all 0.3s ease-in-out;
	align-items: center;
	flex-wrap: wrap;
	display: flex;
	gap: 15px;
	opacity: 0;
	visibility: hidden;
}

.trans_div_box {
	width: 100%;
	display: flex;
}

.trans_div_box p {
	width: 40%;
}

.trans_earning_select {
	width: 100%;
	display: flex;
}

.trans_earning_select p {
	width: 40%;
}

.trans_earning_select select {
	width: 60%;
	height: 40px;
	border: 2px solid black;
	padding: 5px 10px;
	font: 600 14px / 17px "Inter";
	border-radius: 8px;
}

.trans_earning_select select option {
	width: fit-content;
	height: auto;
	font: 600 14px/17px "Inter";
}

.earn-drop-down {
	width: 60%;
}

.earn-drop-down label {
	display: block;
	/* width: 100%; */
}



.color_piker {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 20px;
}

.picker-section {
	width: 50%;
	height: auto;
	display: flex;
	gap: 20px;
}

.linear_gradient {
	width: 100px;
	height: 100px;
	background-color: #000;
}

.partner-color-span {
	display: inline-block;
	width: 100px;
	height: 50px;
	/* background-color: #000; */
	margin-left: 20px;
}



.picker-section-color>button {
	width: 100%;
}




.t_submit_button {
	width: 100%;
	background-color: #257cff;
	color: white;
	font-size: medium;
	font-weight: 600;
	margin: 20px auto;
	padding: 5px;
	box-sizing: border-box;
}

.trans_filter_div {
	padding: 6px;
	box-sizing: border-box;
	box-shadow: 0px 1px 3px 0px #0000007d;
	border-radius: 6px;
	display: flex;
	align-items: center;
	cursor: pointer;
	margin: 0 10px;
	position: relative;
	background-color: white;
}

.trans_filter_box.active {
	right: 0;
	opacity: 1;
	visibility: visible;
}

.css-1ggoop8 svg {
	font-size: 23px !important;
}


.offerFilterbox {
	width: fit-content;
	padding: 6px;
	box-sizing: border-box;
	box-shadow: 0px 1px 3px 0px #0000007d;
	border-radius: 6px;
	display: flex;
	align-items: center;
	cursor: pointer;
	margin: 0 10px;
	position: relative;
	background-color: white;
}


.offer-Main-div {
	position: absolute;
	top: 120%;
	right: -500px;
	border-radius: 3px;
	/* width: 280px; */
	width: 350px;
	height: auto;
	background-color: white;
	box-shadow: 0px 1px 3px 0px #0000007d;
	padding: 8px;
	box-sizing: border-box;
	transition: all 0.3s ease-in-out;
	align-items: center;
	flex-wrap: wrap;
	display: flex;
	gap: 15px;
	opacity: 0;
	visibility: hidden;
}

.offer-filter-item {
	width: 100%;
	display: flex;
}

.offer-filter-item p {
	width: 40%;
}

.offer-Main-div.active {
	right: 0;
	opacity: 1;
	visibility: visible;
	width: auto;
}


.par_dialog_box {
	width: 100%;
	padding: 10px;


}

.part-dia-m-div {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
}

.part-dia-m-div input {
	width: 90%;
	background-color: #fdfdfd;
	border-radius: 10px;
	border: 1px solid white;
	outline: none;
	box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 33%);
	height: 1.1375em;
	margin: 0px;
	padding: 26.5px 14px;
	box-sizing: border-box;
	position: relative;
	border-radius: 18px;
	color: #667085;
	font: 600 14px / 17px "Inter";
}


.partner_filter_data {
	width: fit-content;
	padding: 6px 15px;
	box-sizing: border-box;
	box-shadow: 0px 1px 3px 0px #0000007d;
	border-radius: 6px;
	display: flex;
	align-items: center;
	cursor: pointer;
	/* margin: auto; */
	position: relative;
	background-color: white;
	/* background-color: #2b579a; */
	/* color: white; */
}



.partner-main-div {
	/* width: 100%;
	display: flex; */
	position: absolute;
	top: 120%;
	right: -500px;
	border-radius: 3px;
	/* width: 280px; */
	width: 350px;
	height: auto;
	background-color: white;
	box-shadow: 0px 1px 3px 0px #0000007d;
	padding: 8px;
	box-sizing: border-box;
	transition: all 0.3s ease-in-out;
	align-items: center;
	flex-wrap: wrap;
	display: flex;
	gap: 15px;
	opacity: 0;
	visibility: hidden;
}

.partner-main-div.active {
	right: 0;
	opacity: 1;
	visibility: visible;
	width: fit-content;
	min-width: 260px;
}




.partner_filter_data_popup {
	width: fit-content;
	padding: 6px 15px;
	box-sizing: border-box;
	box-shadow: 0px 1px 3px 0px #0000007d;
	border-radius: 6px;
	display: flex;
	align-items: center;
	cursor: pointer;
	/* margin: auto; */
	position: relative;
	/* background-color: white; */
	background-color: #2b579a;
	/* color: white; */
}


.partner-main-div_popup {

	/* width: 100%;
	display: flex; */
	position: absolute;
	top: 120%;
	right: -500px;
	border-radius: 3px;
	/* width: 280px; */
	width: 350px;
	height: auto;
	background-color: white;
	box-shadow: 0px 1px 3px 0px #0000007d;
	padding: 8px;
	box-sizing: border-box;
	transition: all 0.3s ease-in-out;
	align-items: center;
	flex-wrap: wrap;
	display: flex;
	gap: 15px;
	opacity: 0;
	visibility: hidden;
}


.partner-main-div_popup.active {
	right: 0;
	opacity: 1;
	visibility: visible;
	width: fit-content;
	min-width: 260px;
}


.partner-input-box input {

	height: 50px;
	font-size: large;
	padding-left: 5px;
	margin-top: 5px;
	border-radius: 10px solid white;
}

.allofferSubmit-2 {
	width: 100%;
	height: 29px;
	font: 600 16px / 23px 'Inter';
	color: white;
	background-color: #2b579a;
}


.partner-input-box .partner_radio {
	height: fit-content;
}

.Pending_earn_flex {
	width: 131%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}


.pending_earning_data {
	width: fit-content;
	padding: 6px;
	box-sizing: border-box;
	box-shadow: 0px 1px 3px 0px #0000007d;
	border-radius: 6px;
	display: flex;
	align-items: center;
	cursor: pointer;
	margin: auto;
	position: relative;
	background-color: white;
}

.pending_earning_box {
	width: max-content;
	background-color: white;
	border-radius: 10px;
	/* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12); */
}

.pending_earning_box.p_input_pad input {
	width: 100%;
	padding: 16px;
	font: 500 16px / 18px "inter";
	border-radius: 5px;
}

.pending_earnoing-main-div {
	position: absolute;
	top: 120%;
	right: -500px;
	border-radius: 3px;
	width: 350px;
	height: auto;
	background-color: white;
	box-shadow: 0px 1px 3px 0px #0000007d;
	padding: 8px;
	box-sizing: border-box;
	transition: all 0.3s ease-in-out;
	align-items: center;
	flex-wrap: wrap;
	display: flex;
	gap: 15px;
	opacity: 0;
	visibility: hidden;
}

.pending_earnoing-main-div.active {
	right: 0;
	opacity: 1;
	visibility: visible;
	width: fit-content;
}

.css-1tm81ay {
	width: 250px !important;
}

.clickabuetd {
	color: rgb(84, 84, 246)
}

.pen_input {
	margin: 10px 2px;
	font-weight: 700;
}

.pen_input input {
	padding: 8px 20px;
	border: 5ps solid white;
	border-radius: 5px white;
	font-weight: 600;
}

.ads-limit {
	width: 100%;
	height: fit-content;
	margin-top: 23px;
}

.internal_limits {
	display: flex;
}

.intern-div-1 {
	width: 40%;
}

.intern-div-1 input {
	width: 90%;
	background-color: #fdfdfd;
	border-radius: 10px;
	border: 1px solid white;
	outline: none;
	box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 33%);
	height: 1.1375em;
	margin: 5px 0px 0px 0px;
	padding: 26.5px 14px;
	box-sizing: border-box;
	position: relative;
	border-radius: 18px;
	color: #667085;
	font: 600 14px / 17px "Inter";

}


/* 

.ads-limit p{
	display: inline-block;
	width: fit-content;
} */

.adslimitPara {
	font: 600 24px / 29px "Inter";
	margin-bottom: 10px;
}

.challenge-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 30px;
	/* background-color: #d6dbe5; */
	/* background-color: #d1eaed; */
	padding: 20px;
	border-radius: 10px;
	background-color: #d6dbe5;
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.multiPageData-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 30px;
	padding: 20px;
	/* border-radius: 10px; */
	/* background-color: #d6dbe5; */
	/* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
}

.challenge-field {
	width: 100%;
}

.multi-page-field {
	width: 100%;
}


.challenge-heading {
	display: inline-block;
	width: 25%;
	font: 600 18px/20px "Inter";
	margin-bottom: 5px;
}

.multiPage-table-heading {
	display: inline-block;
	width: auto;
	font: 600 18px/20px "Inter";
	margin-bottom: 5px;
}

.challenge-input {
	width: 50%;
	/* height: 40px; */
	padding: 10px;
	border: 1px solid #2b579a;
	border-radius: 5px;
	font: 500 16px/20px "Inter";
}


.challenge-input.JoditEditor {
	width: 100%;
}


.challenge-img-input {
	display: none;
}

.challenge-preview-image {
	height: 100px;
	width: 150px;
}

.challenge-item-info {
	display: flex;
	align-items: center;
	gap: 10px;
	margin: 10px;
}

.challenge-preview {
	max-width: 1200px;
	width: 100%;
	background-color: aliceblue;
	padding: 10px;
	border: 1px solid #2b579a;
	border-radius: 10px;
}

.multi-page-preview {
	max-width: 1200px;
	width: 100%;
	/* background-color: aliceblue; */
	padding: 10px;
	border: 1px solid #2b579a;
	border-radius: 10px;
}

.challenge-task-tabel {
	width: 100%;
	border-collapse: collapse;
	table-layout: fixed;
	background-color: aliceblue;
	color: darkblue;
}

.challenge-task-tabel tr {
	width: 100%;
	text-transform: capitalize;
}

.challenge-task-tabel th {
	width: fit-content;
	text-align: center;
	border: 2px solid white;
	font: 600 16px / 16px "Inter";
	padding: 5px;
}

.challenge-task-tabel td {
	width: fit-content;
	text-align: center;
	border: 2px solid white;
	padding: 0px;
}





.multi-page-table {
	width: 100%;
	border-collapse: collapse;
	table-layout: fixed;
	/* background-color: aliceblue; */
	color: darkblue;
}

.multi-page-table tr {
	width: 100%;
	text-transform: capitalize;
}

.multi-page-table th {
	width: fit-content;
	text-align: center;
	border: 2px solid white;
	font: 600 16px / 16px "Inter";
	padding: 5px;
}

.multi-page-table td {
	width: fit-content;
	text-align: center;
	border: 2px solid white;
	padding: 0px;
}






.challenge-button {
	width: 120px;
	padding: 5px;
	font: 600 16px/20px "Inter";
	border: 2px solid black;
	border-radius: 5px;
	background-color: #2b579a;
	color: white;
}

.task-success-message {
	width: fit-content;
	height: auto;
	display: flex;
	flex-direction: column;
	gap: 20px;
	/* justify-content: center; */
	align-items: center;
	padding: 20px;
	/* font-size: 400 18px/20px "Inter"; */
}


.task-success-message h2 {
	font: 500 18px/20px "Inter";
}


.ok-cha-button {
	width: 100%;
	padding: 5px;
	font: 500 16px/18px "Inter";
	background-color: #2b579a;
	color: white;
	border-radius: 5px;
}


.add-task-chall {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 10px;
}

.add-task-chall button {
	width: fit-content;
	height: auto;
	padding: 10px;
	background-color: #2b579a;
	color: white;
	font: 500 16px/17px "Inter";
	border-radius: 5px;
}

.disable-challenge {
	width: fit-content;
	height: auto;
	padding: 5px;
	background-color: #2b579a;
	color: white;
	/* font: 400 16px/17px "Inter"; */
	border-radius: 5px;
}


.flex-disable {
	width: 100%;
	display: flex;
	/* gap: 20px; */
	justify-content: space-around;
}

.flex-disable button {
	width: fit-content;
	height: auto;
	padding: 5px 15px;
	background-color: #2b579a;
}

.categoryEditBox.challenge-offer-dialog {
	width: 100%;
	display: flex;
	/* background-color: #d1eaed; */
	text-transform: capitalize;
	/* justify-content: center; */
	/* background-color: #d6dbe5;#D5E5E9 */
	background-color: #D5E5E9;
	border: 1px solid #2b579a;
	align-items: center;
	flex-wrap: wrap;
}

.categoryEditBox.multi-page-data {
	width: 100%;
	/* display: flex; */
	text-transform: capitalize;
	/* background-color: #D5E5E9; */
	border: 1px solid #2b579a;
	align-items: center;
	flex-wrap: wrap;
}



.editCover.fullCover.offer-challenge {
	width: 32%;
}


.in-minutes {
	font: 500 12px/14px "Inter";
}

.css-e8vqgb {
	position: static !important;
}


.video-radio-type {
	width: 100%;
	display: flex;
	align-items: center;
}

.video-radio-type p {
	display: inline-block;
	width: 20%;
}


.invalid-url-page {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.invalid-url-page h1 {
	color: red;
	font: 900 70px/72px "Inter";
}

.input-tag-selectbox {
	width: 80%;
	padding: 20px;
	border-radius: 20px;
}

.multi-page-data-selectbox {
	width: 30%;
	padding: 20px;
	border-radius: 20px;
	font-size: 20px;
}

.refer-stat-header {
	width: 100%;
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	align-items: flex-start;
	gap: 25px;
}

.refer-stat-info {
	width: fit-content;
	display: flex;
	gap: 10px;
	margin-top: 10px;
}

.refer-stat-info-left {
	/* width: fit-content; */
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
	/* margin-top: 10px; */
	border-radius: 10px;
	border: 2px solid #006064;
	padding: 10px;
}


.refer-stat-para {
	width: fit-content;
	height: 80px;
	font: 700 14px / 16px "Inter";
	border: 2px solid black;
	background-color: white;
	padding: 10px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 10px;
	box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.refer-stat-dropdown {
	/* width: fit-content; */
	width: 100%;
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	border-radius: 10px;
	border: 2px solid #006064;
	padding: 10px;
}

.refer-origin-select {
	padding: 10px;
	font: 600 16px/18px "Inter";
	width: 30%;
}

.refer-origin-select options {
	font: 600 16px/18px "Inter";
}

.refer-origin-select-user {
	padding: 10px;
	font: 600 16px/18px "Inter";
	width: 40%;
}

.refer-origin-select-user options {
	font: 600 16px/18px "Inter";
}


.refer-stat-submit {
	width: fit-content;
	height: 40px;
	padding: 10px;
	background-color: #2b579a;
	font: 500 16px/18px "Inter";
	color: white;
	border-radius: 5px;
}

.single-link-stat {
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
	width: 100%;
}


.link-select-box {
	display: flex;
	gap: 20px;
}

.user-refer-selectbox {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
	background-color: white;
	padding: 10px;
	font: 700 14px / 16px "Inter";
	border-radius: 10px;
	border: 2px solid #006064;
}



.user-refer-stat {
	width: fit-content;
	font: 600 14px / 16px "Inter";
	border: 1px solid black;
	background-color: white;
	padding: 10px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.user-refer-stat button {
	width: 100%;
	/* padding: 5px; */
	background-color: #2b579a;
	font: 500 16px/18px "Inter";
	color: white;
	border-radius: 5px;
}

.user-refer-state-para {
	width: fit-content;
	font: 600 14px / 16x "Inter";
	border: 2px solid black;
	background-color: white;
	padding: 10px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
}

.user-refer-state-para-card {
	width: 100%;
	display: flex;
	gap: 10px;
}

.user-detal-section {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.user-button-section {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	justify-content: space-between;
}


.dblock-1st-div {
	width: 33%;
	background-color: white;
	padding: 10px;
	font: 700 14px / 16px "Inter";
	border-radius: 10px;
	border: 2px solid #006064;
}

.dblock-2st-div {
	width: 33%;
	background-color: white;
	padding: 10px;
	font: 700 14px / 16px "Inter";
	border-radius: 10px;
	border: 2px solid #006064;
}

.select-box-user-section {
	width: 100%;
	display: flex;
	gap: 20px;
}

.head-download {
	width: 100%;
	font: 600 16px/18px "Inter";
}

.user-refer-heading {
	font: 600 16px/18px "Inter";
	margin-bottom: 10px;
}

.card-box-para {
	font: 800 20px/22px "Inter";
}

.configuration-edit {
	width: fit-content;
	height: auto;
	padding: 20px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.config-info {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.config-info p {
	width: 40%;
	font: 600 16px/18px "inter"
}

.config-info select {
	width: 100%;
	padding: 10px;
	font: 500 16px/18px "inter";
}

.config-radio-sec {
	width: 70%;
	display: flex;
}

.config-button {
	width: 100%;
	padding: 5px;
	font: 600px 18px/20px "Inter";
	background-color: #257cff;
	color: white;
	border-radius: 5px;
}


.config-info.config-info-flex {
	display: flex;
	flex-direction: column;
	align-items: start;
	gap: 10px;
}

.config-label {
	display: flex;
	flex-direction: column;
	gap: 3px;
}





.refer-origin-list {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.refer-origin-item {
	padding: 8px;
	cursor: pointer;
	border: 1px solid #ccc;
	margin-bottom: 4px;
	border-radius: 4px;
	transition: background-color 0.3s ease;
}

.refer-origin-item:hover {
	background-color: #f0f0f0;
}

.refer-origin-item.selected {
	background-color: #d0d0d0;
	font-weight: bold;
}

.refer-stat-submit:disabled {
	background-color: #ccc;
	cursor: not-allowed;
}


.page-arror-refer {
	display: flex;
	align-items: center;
	gap: 20px;
}

.page-arrow {
	font: 800 30px/32px "Inter";
	cursor: pointer;
}

.page-arrow-num {
	font: 800 20px/22px "Inter";
	cursor: pointer;
}

.page-arrow:hover {
	color: #006064;
}

.card-refer-item {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
	gap: 20px;
	padding: 15px 40px 40px 40px;

}

.refer-button-flex {
	display: flex;
	gap: 20px;
}

.heading-refer-card {
	padding: 20px 40px 0px 40px;
	text-align: center;
}

.card-heading {
	margin-bottom: 10px;
}


.editCover.editBio.m10.meta-imgages {
	width: 33%;
}


.leader-board-nav {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-top: 20px;
}

.lead-go {
	display: inline-block;
	width: fit-content;
	font-size: 16px;
	background-color: #2b579a;
	color: white;
	cursor: pointer;
	border: 2px solid #2b579a;
	border-radius: 5px;
	padding: 5px 10px;
}

.leader-head-section {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
	border-bottom: 2px solid black;
	padding-bottom: 5px;
}

.lead-heading {
	display: inline-block;
	width: 20%;
}


.alloffer-head-btn {
	width: 50%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 30px;
}


.calolr-label label {
	display: inline-block;
	width: 15%;
	font-size: 18px;
	font-weight: 600;
}

.calolr-label input {
	margin-left: 20px;
	height: 50px;
	width: 100px;
}



.partner-lable-sec {
	display: inline-block;
	width: 15%;
	font-size: 18px;
	font-weight: 600;
}


.alert-dialog-box {
	max-width: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	width: fit-content;
	padding: 15px 30px;
	font-size: 20px;

}

.alert-dialog-box-para {
	text-align: center;
}

.alert-dialog-box-trackValue {
	width: 100%;
	display: flex;
	/* flex-direction: column; */
	align-items: center;
	justify-content: center;
	gap: 20px;
	padding: 15px 30px;
	font-size: 20px;
}

.alert-dialog-button {
	width: 100%;
	padding: 5px 0px;
	background-color: black;
	color: white;
	font-size: 18px;
	line-height: 20px;
	font-weight: 600;
	border-radius: 5px;
}

.dialog-alert-p {
	width: fit-content;
	font-size: 35px;
	font-weight: 700;
	color: red;
	line-height: 38px;
}

.part-dia-head {
	margin-bottom: 10px;
}


.userLogsscrollable {
	max-width: 300px;
	/* max-height: 100px; Set a maximum height */
	overflow: auto !important;
	white-space: nowrap;
	padding: 0px 30px !important;
}

.editButton.in-singleOffer {
	padding: 10px 25px;
	margin: 0px;
}


.offer-para-val {
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
}

.descriptionarry {
	display: flex;
	flex-direction: column;
	margin-left: 25%;
	margin-top: 10px;
	gap: 10px;
}


.descri-button {
	padding: 5px 10px;
	margin-left: 10px;
	color: white;
	background-color: #2b579a;
	border-radius: 3px;
	font-weight: 600;
}

.span-crosss {
	padding: 2px 5px;
	margin-left: 10px;
	color: white;
	background-color: #2b579a;
	border-radius: 3px;
	font-weight: 600;
}

.global-note {
	display: inline-block;
	margin-left: 25%;
	font-size: 10px;
	font-weight: 900;
	color: red;

}


.addPartnernDialog {
	width: fit-content;
	padding: 5px 15px;
	background-color: #2b579a;
	font-size: 16px;
	font-weight: 700;
	color: white;
	border-radius: 5px;

}

.xyzdialog {
	width: fit-content;
	height: auto;
	display: flex;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.dialogPartnerChallenge {
	background-color: #D5E5E9;
	padding: 10px;
}

.trackValueMessage-dialog {
	width: fit-content;
	min-width: 250px;
	max-width: 400px;
	padding: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.trackValue-message-para {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	font-weight: 600;
	flex-direction: column;
	gap: 15px;
	color: black;
}


.multipage-input {
	width: auto;
	padding: 15px 10px;
	font-size: 16px;
	border-radius: 5px;
}

.labelClass.border-color {
	width: 100%;
	display: flex;
}


.linear_gradient-single {
	width: 80px;
	height: 60px;
}


.picker-section-color-single {
	width: 40%;
	display: flex;
	gap: 40px;
}

.taskUserInfoFilter {
	display: flex;
	align-items: center;
	gap: 10px;
}

.userInfoSelectButton {
	padding: 5px 10px;
	background-color: #2b579a;
	color: white;
	border: none;
	cursor: pointer;
}

.taskUserInfoFlex {
	display: flex;
	justify-content: space-between;
}


/* .challeng-active{ */
/* width: 50%; */
/* display: flex; */
/* flex-direction: column; */
/* gap:10px; */

/* } */

/* .challeng-active>select{
	padding: 10px;
} */


.challeng-active {
	/* Ensure the class name matches here */
	width: 50%;
	display: flex;
	gap: 10px;
	justify-content: end;
}

.challeng-active>select {
	padding: 10px;
}


.user-profiler {
	min-width: 100%;
	padding: 20px;
	height: auto;
	/* overflow-y: hidden; */
}

.user-profiler h3 {
	padding-bottom: 10px;
	text-align: center;
}


.profile-data {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 7px 0px;
	border-bottom: 1px solid gray;
}

.user-profiler table {
	border-collapse: collapse;
}

.user-profiler table,
th,
td {
	border: 1px solid black;
}


.partner-dialog-box {
	padding: 20px !important;
}


.spinner {
	border: 4px solid rgba(0, 0, 0, 0.1);
	/* border-left-color: #000; */
	border-left-color: white;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	animation: spin 0.5s linear infinite;
	text-align: center;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}


.submit {
	display: flex;
	justify-content: center;
}




.admin-select-box {
	margin: 0px 10px;
	padding: 15px 10px;
	width: 90%;
	border-radius: 18px;
	color: #667085;
	font: 600 14px / 17px "Inter";
}


.disable-partner-section {
	min-width: 350px;
	min-height: 220px;
	padding: 25px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}


.select-1st-half {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-left: 45px;
}

.select-2nd-half {
	display: flex;
	flex-direction: column;
}

.disable-select {
	padding: 10px;
	font: 400 14px/16px "Inter";
	border-radius: 5px;
}


.disable-partner-button {
	padding: 10px 0px;
	font-size: 500 16px/18px "Inter";
	background-color: #2b579a;
	color: white;
	border-radius: 5px;
}

.disable-partner-list {
	width: 100%;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	/* margin: auto; */
}

.disable-partner-list li {
	width: fit-content;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: aliceblue;
	padding: 10px;
	border-radius: 10px;
	gap: 10px;
	font-style: italic;
	font-size: small;

}

.remove-partner-button {
	margin-left: 5px;
	cursor: pointer;
	font-size: 30px;
}

.remove-partner-button:hover {
	background-color: black;
	color: white;
	border-radius: 100%;
}

.loader-sec {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.slidecontainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.block-provider-table {
	width: 100%;
	border-collapse: collapse;
	table-layout: fixed;
}

.provider-table th {
	width: fit-content;
	padding: 10px;
}

.parter-blocl-res ul {
	/* width: ; */
	padding: 0;
}

/* .block-provider-table tr td{
	width: fit-content;
} */

.select-2nd-half h3 {
	margin-left: 45px;
}


.partner-note-heading {
	margin-left: 31%;
}


.access-modules {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.access-modules .access-modules-checkbox {
	width: 40%;
	display: flex;
	align-items: center;
}

.access-modules label {
	display: flex;
	/* margin-left: 8px;	 */
	cursor: pointer;
}



.select-sub-module {
	/* background-color: #0897b7; */
	background-color: #2b579a;
	color: white;
	padding: 5px;
	margin-left: 5px;
	border-radius: 5px;
}


.show-subModule {
	display: flex;
	flex-direction: column;

}

.select-sub-option {
	/* width: 100%; */
	position: relative;
	width: fit-content
}

.show-hide-option {
	width: fit-content;
	/* padding: 10px 35px; */
	color: #fff;
	border-radius: 6px;
	font: 600 13px/15px "Inter";
	background-color: #2b579a;
	cursor: pointer;
	box-sizing: border-box;
}

.show-subModule {
	padding: 10px;
	position: absolute;
	/* width: 120% !important; */
	background-color: white;
	top: 24px;
	left: -10px;
	display: flex;
	flex-direction: column;
	/* text-align: center; */
	border-radius: 11px;
	color: black;
	line-height: 20px;
	z-index: 20;
	cursor: pointer;
	border: 1px solid black;

}

.hide-submodule {
	display: none;
}


.pRelative.w300.accessComponentpart {
	width: 100%;
}


.label-heading {
	display: inline-block;
	width: 30%;
	font-size: 18px;
	font-weight: 600;
}

.checkbox-label {
	margin-right: 10px;
}

.leaderboard-delete-dialog {
	width: fit-content;
	padding: 30px;
}

.delete-leaderboard-button {
	width: 100%;
	display: flex;
	justify-content: space-around;
	margin-top: 30px;
}

.delete-leaderboard-button button {
	padding: 10px 40px;
	font-size: 15px;
	font-weight: 700;
	background-color: #2b579a;
	color: white;
	border-radius: 5px;
}

.trans_div_box.min-max-trans {
	width: 100%;
	display: flex;
	align-items: center;
}

.trans-earning-input {
	width: 90%;
	padding: 10px 20px;
	margin-left: 10px;
}



.slide-input {
	width: 100%;
	display: flex;
	gap: 10px;
}

.slide-input input {
	width: 45%;
	padding: 10px 20px;
}




.editCover.fullCover.incHeight {
	height: 140px;
}



.partnerImageboxTable {
	width: 82px;
	height: 82px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.partnerImageboxTable img {
	width: 100%;
	max-width: 60px;
	max-height: 60px;
	height: 100%;
	object-fit: contain;
}



.multiSelectUser {
	display: flex;
	align-items: center;
	/* Align items vertically */
	gap: 10px;
	/* Add spacing between span and button */
	padding: 8px;
	/* border: 1px solid #ccc; */
	border-radius: 5px;
	/* background-color: #f9f9f9; */
	align-self: flex-start;
}

.multiSelectUser button {
	padding: 5px 15px;
	background-color: #257cff;
	color: white;
	border-radius: 5px;
}

.csvUploadDate {
	margin-bottom: 10px;
}


.user-csv-filter {
	display: flex;
	justify-content: space-between;
	align-items: center;
}


.csv-download {

	/* width: 100%; */
	width: fit-content;
	padding: 10px 30px;
	background-color: #fff;
	margin: auto;
	cursor: pointer;
	box-sizing: border-box;
	color: white;
	background-color: #2b579a;
	border-radius: 6px;

}



.upload-user-csv-flex {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
}


.select-before-radio {
	display: flex;
	width: 100%;
	gap: 25px
}

.user-select-befor {
	display: flex;
	gap: "20px";
}


.already_clear {
	display: flex;
	width: 100%;
	gap: 20px;
}


.pending-button {
	width: 50%;
	display: flex;
	/* justify-content: center; */
	align-items: center;
	gap: 20px;
}


.tSubnitButton.penEarning {
	margin-left: 15px;
}


.uploadCsv-pagination {
	width: 100%;
	display: flex;
	gap: 100px;
}

.uploadCsv-pagination button {
	padding: 10px 20px;
	background-color: #257cff;
	width: fit-content;
	font-size: 500;
	color: white;
	cursor: pointer;
}


.payW100 {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.paymentW50Date {
	width: 5%;
	display: flex;
	justify-content: flex-end;
}

.deviceHeading50 {
	display: flex;
	gap: 20px;
}

.deviceHeading50 button {
	padding: 10px 20px;
	font-weight: 600;
}


.partnerCsvUpload {
	min-width: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	/* flex-wrap: wrap; */
	gap: 20px;

}


.partnerCsvUpload button {
	width: fit-content;
	padding: 10px 20px;
	font-size: 16px;
	font-weight: 600;
	border-radius: 5px;
}


.userParterFilter {
	width: 100%;
	/* background-color: blue; */

}

.userBlockedReason {
	display: 'flex',
}

.userParterFilter {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.fetchBlockedUser {
	padding: 10px 20px;
	font-size: 16px;
	font-weight: 600;
	border-radius: 5px;
	cursor: pointer;
}


.unBlockUser {
	padding: 5px 15px;
	border-radius: 5px;
	font-weight: 600;
	cursor: pointer;
}


.p_info_revenue_Sec {
	display: flex;
	/* justify-content: center; */
	width: 70%;
	gap: 20px;
}


.filterandButtonSection {
	display: flex;
	width: fit-content;
	justify-content: flex-end;
	flex-direction: column;
	gap: 15px;
}


.partnerCsvUpload.button_gap {
	gap: 15px;
}






.reward_popup {
	width: 300px;
	min-height: 300px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 20px;

}

.rewards-heading{
	
	display: flex;
	margin: auto;
	font-size: 18px;
	font-weight: 600;
}

.r_type {
	display: flex;
	flex-direction: column;
}

.r_type select {
	padding: 10px;
	border-radius: 5px;
	font-size: 16px;
	font-weight: 400;
}

.r_type label {
	margin-bottom:5px;
	font-size: 16px;
	font-weight: 600;
}


.r_type input {
	padding: 10px;
	border-radius: 5px;
	font-size: 16px;
	font-weight: 400;
}

.rewardSubmit{
	/* display: inline-block;
	width: 100%; */
	margin: auto;
	background-color: #257cff;
	padding: 10px 20px;
	color: white;
	border-radius: 5px;
	font-weight: 600;
	cursor: pointer;
}


.total-revenue-table{
	width: 100%;
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}


 





@media only screen and (max-width: 600px) {
	.editButton {
		padding: 10px 25px;
	}

	.dFlex.dFlex-2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 20px;
	}

	.tParentDiv.divGrossData {
		display: flex;
		flex-direction: column-reverse;
		align-items: normal;
		gap: 10px;
	}

	.grossDataDateFlex {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.leaderboard-main-div {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 15px;
	}

	.submit-button button {
		width: fit-content;
		padding: 10px 10px;
		color: #fff;
		border-radius: 6px;
		font: 600 16px/17px "Inter";
		background-color: #361b87;
		cursor: pointer;
		box-sizing: border-box;
	}

	.dataTable.notificationTableData {
		display: flex;
		flex-direction: column;
		gap: 29px;
		width: 100%;
		padding: 20px 0;
		box-sizing: border-box;
	}

	.editCover.notificationEditCover {
		width: 100%;
	}

	.editCover.notificationEditCover p {
		width: 30%;
		margin-bottom: 10px;
		color: black;
		font: 600 20px/17px "Inter";

	}

	.logOutButton.notificationButton {
		width: fit-content;
		padding: 10px 35px;
		color: #fff;
		border-radius: 6px;
		font: 600 19px/17px "Inter";
		background-color: #2b579a;
		cursor: pointer;
		box-sizing: border-box;

	}


	.inputTag.notificationTag {
		width: 100%;
		background-color: #fdfdfd;
		border-radius: 10px;
		border: 1px solid white;
		outline: none;
		box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 33%);
		height: 1.1375em;
		margin: 0px;
		padding: 26.5px 14px;
		box-sizing: border-box;
		position: relative;
		border-radius: 3px;
		color: #667085;
		font: 600 18px/21px "Inter";
	}

	.recharts-wrapper .recharts-surface {
		width: 85vw;
	}

	.tParentDiv.transtParentDiv {
		width: 100%;
		display: flex;
		justify-content: space-around;
		align-items: center;
		flex-wrap: wrap;
		gap: 10px;
	}

	.trans-upper-div {
		width: 100%;
		display: flex;
		flex-direction: column-reverse;
		justify-content: space-between;
		align-items: normal;
		gap: 10px;
	}

	.trans-upper-div>div {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 40px;
	}

	.custom-line-chart {
		width: 400px !important;
		height: 400px !important;
		margin: 0px;
	}

	.uploadCsv {
		display: inline-block;
		width: 25%;
		margin-left: 90px;
	}

	.usersFlex,
	.dFlex.w30 {
		width: 100%;
	}

	.usersFlex {
		gap: 0;
		flex-wrap: wrap;
		justify-content: center;
	}

	.usersFlex .uploadCsv {
		display: inline-block;
		width: 50%;
		margin-left: 0px;
	}

	.labelClass label {
		display: inline-block;
		width: 30%;
	}

	.labelandInput {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 15px;
		margin: 20px 0px;
	}

	.divimage.singlePartnerFlex {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 15px;
	}

	.editSingPartnerFlex {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 30px;
	}

	.partnerImage {
		height: auto;
		max-width: 200px;
		width: 160px;
	}

	.popular {
		width: 100%;
		display: flex;
		flex-direction: column-reverse;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.editButton.addCategory.dBlock {
		padding: 10px 15px;
		margin: 0px;
		display: flex;
		color: #fff;
		border-radius: 6px;
		font: 600 16px/17px "Inter";
		background-color: #2b579a;
		cursor: pointer;
		box-sizing: border-box;
		width: 33%;
	}

	.sigleOfferdBlock {
		display: block;
		display: flex;
		width: -moz-fit-content;
		width: 100%;
		flex-direction: column;
	}

	.popular h5 {
		font: 600 23px/23px "Inter";
		margin: 10px 0;
		width: 100%;
	}

	.user-detal-section {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: space-between;
		gap: 20px;
	}

	.dblock-1st-div {
		display: inline-block;
		/* width: fit-content; */
		width: 100%;

		background-color: white;
		padding: 20px;
		font: 700 14px / 16px "Inter";
		border-radius: 10px;
		border: 2px solid #006064;
	}

	.dblock-2st-div {
		display: inline-block;
		width: 100%;
		margin-left: 0px;

		background-color: white;
		padding: 20px;
		font: 700 14px / 16px "Inter";
		border-radius: 10px;
		border: 2px solid #006064;
	}


	.dFlex.UserTransDflex {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		gap: 20px;
	}

	.editButton.addCategory.divwidth {
		width: 45%;
	}

	.profileInfoBox.metaConfigBox {
		box-shadow: 0;
		width: 100%;
		border-radius: 12px;
		padding: 0px;
		box-sizing: border-box;
		margin-top: 0px;
	}

	.addChip {
		width: -moz-fit-content;
		width: fit-content;
		padding: 10px 50px;
		color: #fff;
		border-radius: 6px;
		font: 600 14px/17px "Inter";
		background-color: #2b579a;
		height: 40px;
		margin: 20px;
		cursor: pointer;
		box-sizing: border-box;
	}

	.editCover.metaCover {
		width: 50%;
		margin-bottom: 20px;
	}

	.displayBox {
		width: 45%;
	}
}